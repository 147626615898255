$bodyBGColor: #f5f5f5;
$primaryColor: #3d3d3c;
$secondaryColor: #32c5ff;
$white: #fff;
$black: #000;
$danger: #dc3545;
$grey: #666;
$lightGrey: #999;
$activeSurvey: #81d888;
$closeSurvey: #d88181;
$commingSurvey: #17a2b8;
$draftSurvey: #f9b4378a;
