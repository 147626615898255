/*--- Links --- */
@import "fonts";
@import "colors";
@import "numbers";
@import "breakpoints";
:root {
  --screen-height: 500px;
  --screen-width: 1300px
  --animate-duration: 2s;
  --animate-delay: 3s;
}
/*--- End Links --- */

/* mixins */

@mixin flex($flex, $align-items, $justify-content) {
  display: $flex;
  align-items: $align-items;
  justify-content: $justify-content;
}
@mixin action-box-bg {
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #f5f5f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5',GradientType=0 );
}

// common

body {
  margin: 0;
  padding: 0;
  font-family: $primaryFontFamily;
  font-size: $num14;
  color: $primaryColor;
  background-color: $bodyBGColor;
  overflow-x:hidden ;
}
:focus-visible {
  outline: none;
}

*::selection {
  background-color: $primaryColor !important;
  color: $white !important;
}

.container-fluid{
  padding: 0px 50px;
}
.minHeight {
  min-height: calc(100vh - 50px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
  transition: all 0.3s;
}

p {
  margin: 0;
}

button {
  transition: all 0.3s;
}

img {
  max-width: $per100;
}

.form-control:focus {
  border-color: $secondaryColor;
  box-shadow: none;
}
button:focus,
.form-check-input:focus {
  box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // text-transform: uppercase;
  // font-weight: $bold;
  // letter-spacing: $num1;
  // color: $primaryColor;
}
.btn {
  border: none;
  color: $white;
  border-radius: 0;
  font-size: $num14;
  padding: $num8 0;
  &.disabled {
    background-color: $primaryColor;
    opacity: 0.2;
  }

  &:focus + .btn,
  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &:hover {
    background-color: $primaryColor;
    color: $white;
  }
}

.ptb_30{
  padding: 30px 0px;
}
.ptb_75{
  padding: 75px 0px;
}

.btn-grad-red {
  background-image: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%);
}

.btn-grad-lightblue {
  background-image: linear-gradient(to right, #45b3ad 0%, #8edad6 51%, #51ccc5 100%);
}

.btn-grad-green {
  background-image: linear-gradient(to right, #56ab2f 0%, #a8e063 51%, #56ab2f 100%);
}

.btn-grad-blue {
  background-image: linear-gradient(to right, #1fa2ff 0%, #12d8fa 51%, #1fa2ff 100%);
}

.btn-grad-white {
  background-image: linear-gradient(to right, #ece9e6 0%, #ffffff 51%, #ece9e6 100%);
}

.btn-grad {
  padding: $num10 $num25;
  text-align: center;
  text-transform: capitalize;
  font-weight: $medium;
  transition: 0.5s;
  background-size: 200% auto;
  color: $white;
  border-radius: 0;
  display: inline-block;
  border: 1px solid $bodyBGColor;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  &:hover {
    background-position: right center; /* change the direction of the change here */
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    color: $white;
  }
}
.inline-button {
  padding: $num10 $num25;
  text-align: center;
  text-transform: capitalize;
  font-weight: $medium;
  transition: 0.5s;
  color: $white;
  margin: 0 !important;
  background: transparent;
  border: none;
  border-right: $num2 solid $bodyBGColor;
  box-shadow: none;
  &:hover {
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.075);
    color: $primaryColor;
    background-color: $white;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  &:last-child {
    border-right: none;
  }
}

.color-secondary {
  color: $secondaryColor;
}

.leftButtons {
  width: $per100;
  padding: $num8;
  color: $primaryColor;
  border-bottom: 1px solid #eaeaea;
  text-align: left;
  border-radius: 0;
  position: relative;
  font-size: $num12;
  transition: all 0.3s;
  letter-spacing: $num1;
  font-weight: $medium;
  @include flex(flex, center, flex-start);
}
.page_title.active{
  
}
.swal2-container{
z-index: 100006 !important;
}
/* kendo UI */
// .k-datetime-container{
//   width: 100%;
// }
.k-switch{
  width: 50px;
  .k-switch-container {
    border-color: #32c5ff;
    background-color: #32c5ff;
    width: 35px;
  }
  .k-switch-handle {
      width: 16px;
      height: 16px;
  }
}
.k-switch.k-switch-on{
  .k-switch-handle {
      left: calc(100% - 18px);
      top: -2px;
  }
}
.k-switch.k-switch-off{
  .k-switch-container{
    background-color: transparent;
  }
  .k-switch-handle{
        background-color: #32c5ff;
        left: 3px;
    top: -1px;
  }
}
.k-switch-off:focus, .k-switch-off.k-state-focused,.k-switch-on:focus, .k-switch-on.k-state-focused {
    box-shadow: none;
}

.k-animation-container-shown{
  z-index: 99999 !important;
}

.k-daterangepicker {
  .k-daterangepicker-wrap {
    display: block;
    width: $per100;

    .k-textbox-container {
      width: calc(50% - 10px);

      + span.k-textbox-container {
        margin-left: $num15;
      }

      .k-dateinput {
        .k-dateinput-wrap {
          border: none !important;
        }
      }
    }
  }
}

.k-editor-content {
  .k-input {
    margin: 0;
    box-shadow: none;
    border: none;
  }
  iframe.k-iframe {
    height: auto !important;
  }
}

.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active,
.k-button-group .k-button.k-state-selected,
.k-button-group > input[type="radio"]:checked + .k-button,
.k-button-group > input[type="checkbox"]:checked + .k-button,
.k-button.k-primary {
  border-color: $secondaryColor;
  background-color: $secondaryColor;
  background-image: linear-gradient(rgba(50, 197, 255, 0.2), rgba(50, 197, 255, 0.2));
}
.k-dialog-titlebar {
  background-color: $secondaryColor;
}
.k-datepicker,
.k-timepicker,
.k-datetimepicker {
  width: $per100;
  .k-picker-wrap {
    border: none;
    border-radius: 0;
    height: $per100;
    &.k-state-focused {
      box-shadow: 0 20px 30px 0 #00000014;
      border: 1px solid #32c5ff;
    }
  }
  .k-select {
    border-radius: 0;
  }
}
.k-pager-wrap.k-pager.k-widget {
  background: transparent;
  border: none;
  padding: $num10 $num10 0;

  .k-pager-numbers {
    .k-link {
      color: $primaryColor;
      &.k-state-selected,
      &:hover {
        color: $white;
        background-color: $secondaryColor;
      }
    }
  }
}

.survey-wrapper.intro_survey_wrap{
    min-height: calc(var(--screen-height) - 187px);
    width: 65%;
    margin: 0 auto;
}
/* forms */
form {
  .form-input-main {
    margin: 0 0 $num15;
    position: relative;
    &:focus-within {
      z-index: 2;
    }
    input {
      border-radius: 0;
    }

    label {
      position: relative;
      padding: 0;
      text-align: left;
      display: block;
      margin: 0 0 $num5;
      font-size: $num12;
      font-weight: $semibold;
    }

    .k-dropdown {
      width: $per100;
      height: $num45;
      box-shadow: 0 2px 2px 0 #00000008;
      border: none;

      .k-dropdown-wrap {
        background-color: #fff;
        background-image: none;
        border: 1px solid #f0f0f0;
        border-radius: 0;
        &.k-state-focused {
          box-shadow: 0 20px 30px 0 #00000014;
          border-color: #32c5ff;
        }

        .k-input {
          height: $per100;
        }
      }
    }
  }
  .form-label {
    color: $primaryColor;
    font-weight: $semibold;
    font-size: $num14;
    span {
      color: $danger;
    }
  }
  .form-control,
  .form-select {
    border-radius: 0;
    border: 1px solid #d8d8d8;
    box-shadow: 0 2px 2px 0 #00000008;
    font-size: $num15;
    padding: 0 $num15;
    height: $num45;
    &::placeholder {
      font-size: $num15;
    }
    &:focus {
      box-shadow: 0 $num20 $num30 0 #00000014;
    }
    &:disabled {
      background-color: $bodyBGColor;
    }
  }
  textarea.form-control {
    min-height: $num90;
    padding: $num15;
  }

  .back-to-last {
    display: table;
    margin: 0 0 0 auto;
    background-color: $primaryColor;
    font-size: $num12;

    &:hover {
      background-color: $secondaryColor;
    }
  }

  p.text-danger {
    margin-top: $num5;
    font-size: $num12;
    &.privacy-error {
      bottom: -$num20;
    }
  }
}

.wrapper {
  padding: $num115 0 $num50;
}

/*
  form questions
*/
.question-main {
  margin: 10px 0px 10px;

  .question-number {
    font-size: $num12;
    color: $grey;
    margin: 0 0 $num10;
    line-height: 1;
  }
  .question-text {
    font-size: $num14;
    font-weight: $medium;
    margin: 0 0 $num10;
    position: relative;
    word-break: break-all;
  }
  // checkbox & radio
  .form-check {
    margin: 0 0 $num10;
    // padding: 0;
    font-weight: $medium;
    font-size: $num15;
    position: relative;
    &:last-child {
      margin: 0;
    }

    .form-check-input {
      // position: absolute;
      // top: 0;
      // left: 0;
      // border-radius: 0 !important;
      // margin: 0;
      // border: none;
      // width: $per100;
      // height: $per100;
      // background-color: transparent;
      &:checked {
        // width: $num24 !important;
        // height: $num24 !important;
        // left: -$num1 !important;
        z-index: 9;
        ~ label {
          // border: 1px solid $secondaryColor;
          // padding: $num10 $num10 $num10 $num30;

          &::before {
            // content: "";
            // width: 0;
            // height: 0;
            // border-style: solid;
            // border-width: $num35 $num35 0 0;
            // border-color: $secondaryColor transparent transparent transparent;
            // position: absolute;
            // top: 0;
            // left: 0;
          }

          ~ input[type="text"] {
            display: block;
          }
        }
        &[value="Other"] {
          // right: auto;
          // left: 76px !important;
          ~ label {
            display: inline-block;
            width: $num100;

            ~ input[type="text"] {
              display: inline-block;
              width: calc(100% - 110px);
              margin-left: $num10;
            }
          }
        }
      }
    }
    .form-check-label {
      // position: relative;
      // display: block;
      // padding: $num10;
      // border: 1px solid #f0f0f0;
      // background-color: $white;
      // box-shadow: 0 2px 5px -2px #0000000a;
      word-break: break-word;
    }
    input[type="text"] {
      display: none;
    }
    .k-rating-container{
      margin: 0;
      .k-rating-item{
        padding-left: 0;
        // padding-right: 5px;
      }
    }
    small{
      display: block;
    font-size: 11px;
    color: #bbbbbb;
    }
  }
  .form-check.group_rate{
    margin: 0 0 0px;
    border-bottom: 1px dashed #e5e5e5;
    padding: 10px 0px;
  }
}
button.request_demo{
        padding: 8px 10px;
    font-size: 11px;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 600;
    font-family: "Outfit",sans-serif;
    // background-image: linear-gradient(90deg, #21dbaa, #00b4ef, #0768dd, #5f1ae5);
    background: #fff;
    border: 0px;
    letter-spacing: 1px;
    color: #000;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    border: 2px solid #f4f5fd;
    &:hover{
      background: transparent;
      color: #000;
      transition: all 0.3s ease-in-out;
      border: 2px solid;
    }
  }

/* header */
header {
  // box-shadow: 0 6px 8px -1px rgba(0,0,0,0.13);
  padding: 10px 0px 0px 0px;
  position: absolute;
  width: 100%;
  display: block;
  background: transparent;
  z-index: 999;
  height: auto;
  left: 0px;
  transition: all 0.3s linear 0s;
a.user_login{
      font-weight: 700;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 32px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    &:hover{
      color: #000;
    }
}
  button.request_demo{
        padding: 8px 10px;
    font-size: 11px;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 600;
    font-family: "Outfit",sans-serif;
    // background-image: linear-gradient(90deg, #21dbaa, #00b4ef, #0768dd, #5f1ae5);
    background: #fff;
    border: 0px;
    letter-spacing: 1px;
    color: #000;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    border: 2px solid #fff;
    &:hover{
      background: transparent;
      color: #000;
    transition: all 0.3s ease-in-out;
      border: 2px solid;
    }
  }
  img.nav-logo {
    width: $num65;
  }
span.brand_name{
  font-family: "Outfit",sans-serif;
    color: #fff;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 1px;
    padding-left: 15px;
}
  .navbar {
    padding: 0;
    ul.navbar-nav {
      li {
        + li {
          margin: 0 0 0 $num30;
        }

        a {
            font-weight: 700;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    font-family: "Outfit",sans-serif;
    color: #fff;
          &:hover {
            color: #000;
          }

          svg {
            vertical-align: -$num3;
            font-size: $num16;
          }
        }
      }
      li.active{
        a{
          color: #32c5ff;
        }
      }
      li.signup_btn{
        a{

        }
      }
    }
  }

  /* -.- dropdown -.-*/
  .user-dropdown {
    position: relative;
    background-color: $secondaryColor;
    color: $white;
    margin-left: $num15;

    .dropdown-toggle {
      @include flex(flex, center, center);
      font-size: $num12;
      text-transform: uppercase;
      letter-spacing: $num1;
      font-weight: $semibold;
      padding: $num15;
      &::after {
        border-top-width: 0.5em;
        border-right-width: 0.5em;
        border-left-width: 0.5em;
      }

      %avatar {
        background: $white;
        color: $secondaryColor;
        display: grid;
        place-content: center;
        font-size: $num12;
        font-weight: $semibold;
        margin: 0 $num5 0 0;
      }

      @mixin avatar($size, $circle: false) {
        width: $size;
        height: $size;

        @if $circle {
          border-radius: $size / 2;
        }
      }

      .square-av {
        @include avatar(30px, $circle: false);
        @extend %avatar;
      }
      .circle-av {
        @include avatar(30px, $circle: true);
        @extend %avatar;
      }
    }

    .dropdown-menu {
      border: none;

      &[data-bs-popper] {
        right: 0;
        left: auto;
      }

      li {
        + li {
          margin-left: auto !important;
        }

        .dropdown-item {
          text-transform: capitalize;
          &.active {
            background-color: $secondaryColor;
            color: $white;
          }
        }
      }
    }
  }
}
header.fixed-me{
  box-shadow: 0 6px 8px -1px rgba(0,0,0,0.13);
  z-index: 9999;
  position: fixed;
  top: 0px;
  left: 0px;
  background: #fff;
  padding: 15px 0px;
  transition: all 0.3s linear 0s;
  button.request_demo{
    background: #000;
    color: #fff;
    border-color: #000;
  }
  a.user_login{
    color: #000;
  }
  img.black_logo{
    width: 50px;
  }
  span.brand_name{
    color: #000;
  }
  .navbar{
     ul.navbar-nav{
       li{
        a{
          color: #000;
        }
       }
     }
  }
}
header.intro_header{
      padding: 10px 0px;
    display: block;
    width: 100%;
    z-index: 99;
    background-color: #fff;
    position: sticky;
    top: 0;
    box-shadow: 0 5px 20px 0 #0000000f;
    max-height: 100%;
  img.nav-logo{
    width: 70px;
  }
  h4{
    margin-bottom: 0;
  }
}
header.inner_header{
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
    padding: 0;
    .navbar {
      padding: 0;
      img.nav-logo {
        width: 50px;
      }
      ul.navbar-nav li a {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #3d3d3c;
        svg{
            font-size: 16px;
            vertical-align: -3px;
        }
      }
    }
}
/*  login  */

.login-reg-main {
  width: $per100;
  height: 100vh;
  display: grid;
  place-content: center;

  .registration-box-height {
    position: relative;
    z-index: 1;
    max-height: 100vh;
  }

  .reg-timeline {
    display: flex;
    margin: $num15 0;

    span {
      flex: 1;
      position: relative;
      svg {
        position: relative;
        font-size: $num20;
        color: #f5f5f5;
        z-index: 1;
        width: $num30;
        height: $num30;
        background-color: #e0e0e0;
        border-radius: $num100;
        display: block;
        margin: 0 auto;
      }
      &:before {
        content: "";
        z-index: 1;
        background-color: $bodyBGColor;
        font-size: $num12;
        margin: 0 0 $num5;
        color: #e0e0e0;
        display: block;
      }
      &::after {
        content: "";
        background-color: #e0e0e0;
        height: $num1;
        position: absolute;
        left: 0;
        right: -$per100;
        bottom: $num15;
      }

      &:first-child {
        &::before {
          content: "User Details";
          text-align: left;
        }

        svg {
          margin-left: 0;
        }
      }
      &:nth-child(2) {
        &::before {
          content: "Organization Details";
          text-align: center;
        }
        &::after {
          left: $per50;
          right: -$per100;
        }
      }
      &:nth-child(3) {
        &::before {
          content: "OTP";
          text-align: right;
        }
        &::after {
          content: none;
        }

        svg {
          margin-right: 0;
        }
      }
      &:last-child {
        &::before {
          content: "OTP";
          text-align: right;
        }
        &::after {
          left: $per100;
          right: 0;
        }
        svg {
          margin-right: 0;
        }
      }

      &.bg-completed {
        &::before {
          color: $secondaryColor;
        }
        &::after {
          background-color: $secondaryColor;
        }
        svg {
          background-color: $secondaryColor;
        }
      }
    }
  }

  .form-account {
    padding: $num15;
    margin: auto;
    width: 370px;
    position: relative;
    h4 {
      position: relative;
      margin: 0 0 $num12;
      padding: 0 0 $num12;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: $num1;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(241, 241, 241, 1) 50%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(241, 241, 241, 1) 50%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(241, 241, 241, 1) 50%, rgba(255, 255, 255, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
      }
    }
    form {
      img {
        width: $num80;
      }
      .checkbox {
        font-weight: $regular;

        input {
          vertical-align: middle;
        }
      }
      .form-input-main {
        /* register as radio */
        .form-check {
          min-height: auto;
          padding-left: 0;
          margin: 0 $num10 0 0;
          position: relative;

          input[type="radio"] {
            border-radius: $per50;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0 !important;
            margin: 0;
            border: none;
            width: $per100;
            height: $per100;
            background-color: transparent;
            &:checked {
              width: $num12 !important;
              height: $num12 !important;
              left: auto !important;
              z-index: 9;
              top: $num1;
              left: 0;
              ~ label {
                border: 1px solid $secondaryColor;
                padding: $num10;
                &::before {
                  content: "";
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: $num20 $num20 0 0;
                  border-color: $secondaryColor transparent transparent transparent;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }

            + label {
              position: relative;
              padding: $num10;
              border: $num1 solid #f0f0f0;
              box-shadow: 0 2px 5px -2px #0000000a;
              margin: 0;
            }
          }
        }
        /* register as radio end */

        #otp {
          letter-spacing: $num20;
        }
      }
      .otp-sent {
        font-size: $num10;
        text-align: left;

        span {
          color: $secondaryColor;
        }
      }

      .reg-steps {
        position: relative;
        overflow: hidden;
        display: flex;

        .step {
          flex: 0 0 auto;
          width: $per100;
        }
      }

      .otp-counter {
        font-size: $num12;
        color: $primaryColor;
      }

      .previous-next {
        position: absolute;
        top: $per50;
        left: 0;
        right: 0;
        transform: translate(0, -$per50);
        z-index: -1;
        button {
          position: absolute;
          color: $white;
          font-size: $num12;
          padding: $num10;
          display: flex;
          align-items: center;
          background-color: $secondaryColor;
          border: none;
          svg {
            font-size: $num16;
            color: $white;
          }
          &:hover {
            background-color: $secondaryColor;
          }
          &.btn-prev {
            left: -$num30;
            border-radius: 0.3em 0 0 0.3em;
            svg {
              margin-right: $num5;
            }
            &:hover {
              left: -$num65;
            }
          }
          &.btn-next {
            right: -$num30;
            border-radius: 0 0.3em 0.3em 0;
            svg {
              margin-left: $num5;
            }
            &:hover {
              right: -$num65;
            }
          }
        }
      }
    }
  }
  .forgot-link {
    font-size: $num12;
    margin: $num15 0 0;
    a {
      margin: 0 $num5;
    }
  }
  a {
    color: $secondaryColor;
    &:hover {
      color: $primaryColor;
    }
  }
}

.show-hide-password {
  position: absolute;
  top: $num38;
  right: $num15;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: $num15;
  }
}
/*  login end  */

/* footer */

footer {
  font-size: $num12;
  z-index: 9;
  position: relative;
  background: $white;
  max-height: $num50;
  border-top: 1px solid #ccc;
  padding: 15px 0;
  width: 100%;
  z-index: 99;
  a{
    color: #00aef3;
  }
}
footer.intro_footer{
    padding: 15px 0px;
    // display: block;
    // position: fixed;
    // bottom: 0px;
    // left: 0px;
    width: 100%;
    z-index: 99;
    border-top: 1px solid #ccc;
    background: #fff;
    font-size: 12px;
    // right: 0;
    a{
      color: #3905c0;
    }
}
/* footer end */

/* introduction */
.introduction-main {
    padding: 35px 0px;
    width: 100%;
    min-height: calc(var(--screen-height) - 139px);
    background: #fff;
    // padding-bottom: 75px;
    position: relative;
  // position: absolute;
  // top: $per50;
  // left: $per50;
  // transform: translate(-50%, -50%);
  // width: $per100;

  .survey-image {
    margin-bottom: 30px;
    text-align: center;
    // background-color: $white;
    // padding: $num15;
    // float: left;
    img{
         padding: 3px;
    border-radius: 10%;
    height: 125px;
    width: 125px;
    border: 1px solid #c5c5c5;
    }
  }
  h3 {
    .survey-date {
      display: block;
      font-size: $num12;
    }
  }
  p {
    // font-size: $num16;
    // line-height: $num27;
  }
  p.survey-date{
    font-size: 14px;
    span.start_date{
      color: #28a745;
      font-weight: 600;
      font-size: 16px;
    }
    span.end_date{
      color: #dc3545;
      font-weight: 600;
      padding-left: 15px;
      font-size: 16px;
    }
    span.text-danger{
      position: absolute;
      right: 50px;
      top: 15px;
      color: #fff !important;
      background: #dc3545;
      padding: 0px 10px 0px 20px;
      border-radius: 3px;
      &:before{
       content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #fff;
    top: 8px;
    left: 10px;
    border-radius: 100%;
      }
    }
    span.upcoming_info{
      position: absolute;
      right: 50px;
      top: 15px;
      color: #fff !important;
      background: #17a2b8;
      padding: 0px 10px 0px 20px;
      border-radius: 3px;
      &:before{
       content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #fff;
    top: 8px;
    left: 10px;
    border-radius: 100%;
      }
    }
    
  }
  .intro_block{
    img{
      display: block;
    margin: 10px auto 0px;
    width: 25%;
    }
    a{
      span{
        color: #32c5ff !important;
      }
    }
  }
}

/* survey-main */
// .survey-main {
  .survey-wrapper {
    .left-panel {
      top: 0;
      padding: $num15;
    }
  }
// }


.survey-main{
  
  .survey-wrapper{
    .question_boxes {
      border-radius: 10px;
      position: relative;
      box-shadow: rgba(149,157,165,0.20) 0px 8px 24px !important;
    }
    .question-main{    margin: 10px 0px 10px;
      .question-number{
          font-size: 13px;
          border: 1px solid #d8d8d8;
          border-radius: 5px;
          display: block;
          width: auto;
          height: auto;
          position: absolute;
          left: 15px;
          top: -11px;
          background: #fff;
          font-weight: 700;
          padding: 5px 15px;
      }
    }
  }
}

/* create survey */
.create-survey-main,
.add-question-main,
.profile-main,
.survey-settings-main {
  position: relative;

  .left-panel {
    /* view after collapse */
    &.slideLeft {
      .accordion {
        .accordion-item {
          h2 {
            .accordion-button {
              font-size: 0;
              padding: $num20;
            }
          }
          .accordion-collapse {
            .accordion-body {
              .btn,
              .settings-items {
                font-size: 0;
                display: grid;
                place-content: center;

                svg {
                  margin-right: 0;
                  border-right: 0;
                  padding: 0 $num5;
                }
              }
            }
          }
        }
      }
    }
    /* view after collapse end */
    .accordion {
      .accordion-item {
        h2 {
          .accordion-button {
            color: $white;
            border-radius: 0;
            text-transform: uppercase;
            background-color: $secondaryColor;
            font-size: $num12;
            padding: 1rem $num10;
            &:hover {
              background-color: $primaryColor;
            }
            &:not(.collapsed) {
              svg {
                transform: rotate(180deg);
              }
            }
            &:after {
              display: none;
            }
            &.collapsed {
              margin-bottom: $num5;
            }

            svg {
              font-size: $num22;
              transition: all 0.3s;
              position: absolute;
              right: $num15;
            }
          }
        }
        .accordion-collapse {
          .accordion-body {
            padding: 0;
            .btn,
            .settings-items {
              @extend .leftButtons;

              // &:last-child {
              //   border: none;
              // }
              &:hover {
                background-color: $white;
                color: $secondaryColor;
                box-shadow: 0 0 20px 0 #0000001c;
              }
              &.btn-added {
                background-color: #f8f9fa;
                opacity: 0.3;
                pointer-events: none;
                svg {
                  &.field-add {
                    display: none;
                  }
                  &.field-added {
                    display: block;
                  }
                }
              }

              svg {
                position: relative;
                margin-right: $num10;
                padding-right: $num10;
                font-size: $num30;
                border-right: 1px solid #eaeaea;
                &.field-add {
                  display: block;
                }
                &.field-added {
                  display: none;
                }
              }

              span {
                svg {
                  position: relative;
                  left: 0;
                  top: 0;
                  transform: none;
                  font-size: $num15;
                  margin-right: $num2;
                  vertical-align: text-bottom;
                  right: 0;
                }
              }
            }

            .survey-setting-left {
              max-height: calc(100vh - 211px);
              overflow-y: auto;
              button {
                flex-direction: column;
                align-items: self-start;
                padding: $num15 $num10;

                h6 {
                  font-size: $num12;
                  color: $grey;
                  margin: 0 0 $num5;
                  transition: all 0.3s;
                }

                p {
                  font-size: $num11;
                  color: $primaryColor;
                }

                &:hover {
                  h6 {
                    color: $secondaryColor;
                  }
                }
              }

              /* width */
              &::-webkit-scrollbar {
                width: $num3;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: $white;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: $lightGrey;
                border-radius: $num10;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb {
                &:hover {
                  background: $grey;
                }
              }
            }
          }
        }
      }
    }
  }

  .right-panel {
    %removePsuedo {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      right: 0px;
      content: "";
      border-color: transparent transparent transparent #a21c29;
    }
    .create-survey-form,
    .add-question-form {
      padding: 0 $num30;
      max-width: $per90;
      margin: 0 auto;

      form {
        .row {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        .form-control {
          margin: 0;
        }

        [class*="col"] {
          position: relative;
        }

        .drag-drop-area {
          border: 1px dashed #ddd;
          padding: $num25;

          [class*="col-"] {
            &:last-child {
              margin-bottom: 0 !important;
            }
          }

          .add-question-button {
            min-width: auto;
            background-color: $secondaryColor;
            border: none;
            color: $white;
            font-size: $num24;
            line-height: 1;
            width: $num36;
            height: $num36;
            display: grid;
            place-content: center;
            margin-inline: auto;
            transition: all 0.3s;
            &:hover {
              background-color: $white;
              color: $secondaryColor;
            }
            &::after {
              content: none;
            }
          }
        }
        .remove-field {
          position: absolute;
          right: -$num5;
          cursor: pointer;
          background-color: $danger;
          color: $white !important;
          width: $num25;
          height: $num25;
          display: grid;
          place-content: center;
          top: 0;
          box-shadow: -3px 0 3px 0 #00000026;
          font-size: $num10;
          margin: $num5 0 0;
          &::before {
            @extend %removePsuedo;
            border-width: $num5 0 0 $num5;
            top: -$num5;
          }
          &::after {
            @extend %removePsuedo;
            border-width: 0 0 $num5 $num5;
            bottom: -$num5;
          }
        }
      }
      hr {
        opacity: 0.1;
      }

      button {
        min-width: $num150;
        &.dropdown-toggle {
          svg {
            font-size: $num16;
          }
          &::after {
            content: none;
          }
        }
      }
      .k-input {
        height: $per100;
        &::placeholder {
          font-size: $num15;
        }
      }
      .k-editor {
        margin: $num5 0 0;

        .k-toolbar {
          .k-button-group {
            .k-button {
              min-width: auto;
              &.k-button-icon {
                width: auto;
                height: auto;
              }
            }
          }
          .k-dropdown,
          .k-dropdowntree {
            width: auto;
            line-height: 1;

            .k-input {
              padding: 0.375rem;
            }
          }
        }
      }
    }
  }
}

/* survey */
// .survey-main{
//   padding: 0 50px;
  .survey-wrapper {
    display: flex;
    
        .question_boxes{
          border-radius: 10px;
          position: relative;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
        }
    .survey-timings {
          font-size: 14px;
      span.start_date{
  color: #28a745;
      font-weight: 600;
      font-size: 16px;
      }
      span.end_date{
      color: #dc3545;
      font-weight: 600;
      padding-left: 15px;
      font-size: 16px;
      }
    }

    .left-panel {
      width: 260px;
      position: fixed;
      top: $num60;
      left: 0;
      background-color: $white;
      bottom: 0;
      padding: 0;
      flex: 0 0 auto;
      z-index: 9;
      transition: all 0.3s linear;
      overflow: hidden;

      &.slideLeft {
        // left: -260px !important;
        width: $num50;
        .logo {
          height: auto;
          width: $per100;
          img {
            height: auto;
          }
        }
        h4 {
          margin: $num20 0 0 0;
          font-size: $num16;
          writing-mode: vertical-lr;
          transition: font-size 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
        }
        .short-intro,
        .survey-timings {
          display: none !important;
        }
        .short-intro {
          + span {
            display: none;
          }
        }
        .powered-by {
          display: none;
        }
      }

      .left-panel-wrape {
        max-height: calc(100vh - 125px);
        overflow-y: auto;

        /* scroll bar */
        /* width */
        &::-webkit-scrollbar {
          width: $num2;
          background-color: $bodyBGColor;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: $num10;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #aaa;
        }
      }

      .logo {
        height: $num80;
        width: $per100;

        img {
          height: $per100;
        }
      }

      h4 {
        font-family: $secondaryFontFamily;
        font-weight: $medium;
        margin: $num30 0 0;
        font-size: $num22;
        transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
      }

      .survey-timings {
        margin: $num10 0 0;
        line-height: 1;
        font-size: $num12;
        transition-delay: 0.2s;

        p {
          color: $grey;
          font-weight: $medium;
          display: inline-block;
          background-color: $bodyBGColor;
          padding: $num5 $num10;
          border-radius: $num100;
          span {
            color: $primaryColor;
            margin: $num5 0 0;
            font-size: $num12;
          }

          + p {
            margin: $num5 0 0 0;
          }
        }
      }

      .short-intro {
        transition: font-size 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
        font-size: $num12;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
        overflow: hidden;

        @media screen and (min-width: $xxl) {
          -webkit-line-clamp: 20;
        }

        + span {
          font-size: $num12;
        }
      }

      .powered-by {
        position: absolute;
        bottom: 0;
        background-color: $secondaryColor;
        color: $white;
        padding: $num15;
        left: 0;
        right: 0;
        font-family: $secondaryFontFamily;
        font-size: $num13;
        &:hover {
          background-color: $primaryColor;

          span {
            color: $primaryColor;
            &::before {
              border-color: transparent transparent transparent $primaryColor;
            }
          }
        }

        span {
          position: absolute;
          top: $num5;
          right: $num5;
          background-color: $white;
          color: $secondaryColor;
          width: $num35;
          height: $num35;
          text-align: center;
          transition: all 0.3s;
          &::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: $num35 0 0 $num35;
            border-color: transparent transparent transparent $secondaryColor;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            transition: all 0.3s;
          }

          svg {
            position: relative;
            right: -$num8;
            top: 0;
            font-size: $num16;
          }
        }
      }
      .toggle-left {
        position: absolute;
        top: $num30;
        right: -$num15;
        font-size: $num18;
        width: $num30;
        // display: grid;
        place-content: center;
        transition: all 0.3s;
        color: $primaryColor;
        cursor: pointer;
        height: $num30;
        border-radius: $num100;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        background-color: $white;
        border-bottom: 1px solid #ccc;
        z-index: 9999;
        &:hover {
          color: $secondaryColor;
          border-bottom-color: $secondaryColor;
        }

        svg {
          position: relative;
          // transition: all 0.3s;
        }
      }
      #pageAdd{
        .accordion-body{
          // overflow-y: scroll !important;
          // height: 335px;
          // overflow: hidden;
          max-height: calc(100vh - 325px);
          overflow-y: auto !important;
          overflow: hidden;

           /* width */
              &::-webkit-scrollbar {
                width: $num3;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: $white;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: $lightGrey;
                border-radius: $num10;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb {
                &:hover {
                  background: $grey;
                }
              }

        }
      }
    }

    .right-panel {
          flex: 0 0 auto;
    left: 260px;
    position: relative;
    transition: all .3s;
    width: calc(100% - 260px);
      &.right-full {
        width: calc(100% - 50px);
        left: $num50;

        .pager-main {
          width: calc(100% - 50px);
        }
      }

      .questions-wrapper {
        max-width: 100%;
        margin: 0 auto;
        padding-bottom: $num35 !important;
      }

      .pager-main {
        position: fixed;
        bottom: 0;
        width: calc(100% - 260px);
        padding: $num50 0 $num10;
        background: $bodyBGColor;
        background: linear-gradient(0deg, #fff 50%, #ffffff00 100%);

        .progress {
          height: $num10;
          font-size: $num10;
          border-radius: $num10;
        }
      }
    }
    .right-panel.intro_survey{
          width: calc(100% - 0px);
          left: 0;
    }
  }
  .survey-wrapper.survey_panel{
    .left-panel{
    max-height: calc(var(--screen-height) - 139px);
    overflow-y: auto !important;
    overflow: hidden;
    top: 90px;
    display: none;
      /* width */
              &::-webkit-scrollbar {
                width: $num3;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: $white;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: $lightGrey;
                border-radius: $num10;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb {
                &:hover {
                  background: $grey;
                }
              }
    }
    .btn.btn-added{
          background: #f8f9fa;
    width: 100%;
    padding: 8px;
    color: #3d3d3c;
    border-bottom: 1px solid #eaeaea;
    text-align: left;
    position: relative;
    font-size: 12px;
    transition: all 0.3s;
    letter-spacing: 1px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg{
          position: relative;
    border-right: 1px solid #eaeaea;
    font-size: 30px;
    margin-right: 10px;
    padding-right: 10px;
    }
    }
    .left-panel.slideLeft{
      .btn.btn-added{
            font-size: 0;
    display: grid;
    place-content: center;
    svg{
          margin-right: 0;
    border-right: 0;
    padding: 0 5px;
    }
      }
    }
  }
// }

/* thank you */
.thankyou-main {
    height: 79vh;
    align-items: center;
    justify-content: center;
    display: flex;

    .thankyou-message-card {
      // position: absolute;
      // top: $per50;
      // left: $per50;
      // transform: translate(-$per50, -$per50);
      text-align: center;

      .thankyou-icon {
        margin: 0 0 $num15;
        color: $secondaryColor;
        font-size: $num80;
        position: relative;
        line-height: 0;
      }

      .thankyou-message {
        h4 {
          color: $secondaryColor;
        }
    color: #32c5ff;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 600;
    padding: 0 15%;
      }
    }
}

.page-not-found-main {
  height: 100vh;
  position: relative;

  .page-not-found-message {
    position: absolute;
    top: $per50;
    left: $per50;
    transform: translate(-$per50, -$per50);
    text-align: center;

    .error-icon {
      margin: 0 0 $num15;
      color: $danger;
      font-size: $num80;
      position: relative;
      line-height: 0;
    }

    h4 {
      color: $danger;
    }
    h5 {
      color: #a9a9a9;
    }
    p {
      letter-spacing: $num1;
      color: #a9a9a9;
      font-weight: $medium;
      a {
        color: $secondaryColor;
        &:hover {
          color: $primaryColor;
        }
      }
    }
  }
}

/*
  dashboard
*/
.dashboard-main.home_wrap{
  // padding: 60px 0px 25px 0px;
  background: #f4f5fd;
}
.dashboard-main {
    height: calc(var(--screen-height)) - 110px;
    padding: 75px 0px 15px 0px;
  .welcome-line {
    font-size: $num18;
    a {
      color: $secondaryColor;
      &:hover {
        color: $primaryColor;
      }
    }
  }

  .dashboard-details {
    margin: $num15 0 0;

    .survey-card {
      background-color: $white;
      padding: $num15;
      font-size: $num12;
      color: #888;
      @include flex(flex, center, space-between);
      height: $per100;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.5);
      border-left: $num3 solid #32c5ff94;
      &.active-card {
        border-left-color: $activeSurvey;
      }
      &.closed-card {
        border-left-color: $closeSurvey;
      }
      &.comming-card {
        border-left-color: $commingSurvey;
      }
      &.draft-card{
        border-left-color:#f9b4378a
      }
      span {
        color: $primaryColor;
        font-size: $num16;
      }
    }
    .survey-create {
      display: grid;
      background-color: $secondaryColor;
      height: $per100;
      place-content: center;
      color: $white;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
      border: 1px solid #f5f5f5;
      font-weight: $semibold;
      font-size: $num12;
      text-transform: uppercase;
      &:hover {
        background-color: $primaryColor;
      }
    }
  }

  .all-surveys {
    margin: $num30 0 0;

    .dashboard-data-wrap {
      background-color: #fff;
      padding: 15px;

      .k-listview {
        background-color: transparent;
        border: none;
        font-family: $primaryFontFamily;

        .k-listview-content {
          overflow: visible;
        }
      }

      .k-pager-wrap {
        padding: 0;
        font-size: $num12;
      }

      .all-survey-card {
        background-color: $white;
        padding: $num15 0 $num15 $num15;
        border: 1px solid #e6e6e6;
        transition: all 0.3s;
        position: relative;
        display: flex;
        margin: 0 0 $num20;
        &:hover {
          border-color: $secondaryColor;
        }

        .survey-title {
          font-size: $num14;
          font-weight: $semibold;
          flex-grow: 10;
          margin-top: $num15;

          .survey-dates {
            font-weight: $regular;
            font-size: $num12;
            margin: $num4 0 0;
            letter-spacing: $num1;
          }

          .survey-tag {
            position: absolute;
            top: 0;
            text-transform: lowercase;
            width: 100%;
            .survey-status {
              padding: $num5;
              color: $white;
              display: inline-block;
              line-height: 1;
              font-size: $num12;
              border-radius: 0;

              &.open-survey {
                background-color: $activeSurvey;
              }
              &.close-survey {
                background-color: $closeSurvey;
              }
              &.comming-survey {
                background-color: #17a2b8;
              }
              &.draft-survey {
                background-color: $draftSurvey;
              }

              + span {
                margin-left: $num5;
              }
            }
            span.closing_soon{
              position: absolute;
              top: 0px;
              right: 15px;
              font-size: 10px;
              font-weight: 600;
              background: #d88181;
              color: #fff;
              padding: 3px 5px;
              border-radius: 0px 0 0 5px;
              letter-spacing: 0.5px;
            }
          }
        }

        .survey-data {
          padding: 0;
          margin: 0;
          @include flex(flex, center, end);
          li {
            list-style: none;
            display: inline-block;
            padding: $num30 $num60 $num30 $num20;
            font-size: $num14;
            line-height: 1;
            border-right: 1px dotted #e6e6e6;

            span {
              display: block;
              font-size: $num12;
              color: #888;
              margin: $num5 0 0;
            }
            .btn{
                  color: #32c5ff;
    font-size: 20px;
    span{
      color: #32c5ff;
    }
            }
          }

          &.survey-options {
            li {
              border: none;
              padding: 0;
              width: $num100;
              height: $per100;
              cursor: pointer;
              transition: all 0.3s;
              @include flex(flex, center, center);
              flex-direction: column;
              position: relative;
              font-size: $num30;
              color: $secondaryColor;
              &:hover {
                color: $primaryColor;
              }

              span {
                margin: 0;
              }

              .option-open {
                position: absolute;
                top: $num80;
                background-color: $white;
                box-shadow: 0 2px 8px 0 rgba(0,0,0,0.14);
                border: 1px solid #e6e6e6;
                min-width: $num150;
                z-index: 9;
                &:before {
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 $num10 $num10 $num10;
                  border-color: transparent transparent #ffffff transparent;
                  content: "";
                  position: absolute;
                  top: -$num10;
                  left: $per50;
                  transform: translate(-$per50, 0);
                  filter: drop-shadow(0px -1px 0 #ddd);
                }

                li {
                  font-size: $num12;
                  @include flex(flex, center, flex-start);
                  flex-direction: row;
                  padding: $num10;
                  border-bottom: 1px solid #e6e6e6;
                  color: $primaryColor;
                  transition: all 0.3s;
                  font-weight: $semibold;
                  width: $per100;
                  &:last-child {
                    border: none;
                  }
                  &:hover {
                    background-color: #f9f9f9;
                    color: $secondaryColor;
                  }

                  svg {
                    margin-right: $num7;
                    font-size: $num16;
                  }
                }
              }
            }
          }
        }
        .survey-card-actions {
          width: $num100;
          display: grid;
          place-content: center;
          &.dropdown {
            button {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}

.add-question-main {
  .add-question-form {
    .question-main {
      margin: 0;
      .question-main-top {
        margin: 0 0 $num15;
        line-height: 1;
        @include flex(flex, center, flex-start);
        .question-number {
          margin: 0;
        }
        .question-edit {
          @include flex(inline-flex, center, flex-end);
          font-size: $num12;
          color: $grey;
          margin-left: $num10;
          padding-left: $num10;
          border-left: 1px solid $lightGrey;
          .dropdown {
            button {
              min-width: auto;
              color: $lightGrey;
              background: transparent;
              font-weight: $medium;
              line-height: 1;
              svg {
                font-size: $num16;
                margin: 0 0 0 $num2;
              }
              &:hover {
                color: $primaryColor;
              }
            }
          }
        }
      }
      .question-text {
        margin-top: 10px;
        @include flex(flex, center, space-between);

        .input-group {
          width: calc(100% - 40px);
        }

        .attach-document {
          font-size: $num20;
          line-height: 0;
          width: $num30;
          height: $num30;
          padding: $num5;
          border: 1px solid #ccc;
          background: linear-gradient(180deg, #fff 0, #f5f5f5);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f5f5f5",GradientType=0);
          cursor: pointer;
          transition: all 0.3s;
          display: grid;
          place-content: center;

          &:hover {
            background: $secondaryColor;
            color: $white;
            border-color: #00aef3;
          }
        }

        .question-label {
          width: $per100;
          word-break: break-all;
          cursor: pointer;
        }
        .form-control {
          padding: 0;
          border: none;
          font-weight: $semibold;
          width: $per100;
          margin: 0;
        }
      }
    }
    .form-control {
      box-shadow: none;
      cursor: pointer;
      height: auto;
      font-weight: $regular;
      font-size: $num14;
      line-height: 1;
      border-radius: 0;
      &:focus {
        border: 1px solid $secondaryColor !important;
        box-shadow: 0 20px 30px 0 #00000014;
        padding: $num10 !important;
        cursor: default;
      }
    }

    input[type="file"] {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      cursor: pointer !important;
    }

    .dropdown-options-main {
      margin: $num15 0 0;

      .input-group {
        margin: 0 0 $num10;

        .form-control {
          padding: $num10;
          font-size: $num12;
          color: $grey;
          &::placeholder {
            font-size: $num12;
          }
        }

        button {
          min-width: $num40 !important;
          margin-left: $num5 !important;
          background: transparent;
          font-size: $num16;
          z-index: 1;
          transition: all 0.3s;
        }
        .delete-option {
          border: 1px solid $danger;
          color: $danger;
          &:hover {
            background-color: $danger;
            color: $white;
          }
        }
        .add-option {
          border: 1px solid $secondaryColor;
          color: $secondaryColor;
          &:hover {
            background-color: $secondaryColor;
            color: $white;
          }
        }
        .disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}

.add-quesstion-buttons {
  margin-top: $num25;
  @include flex(inline-flex, center, center);
  background: linear-gradient(to right, #32ffff -50%, #32c5ff 50%, #32ffff 150%);
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.8) !important;
  border: $num1 solid $bodyBGColor;

  button {
    margin: 0 $num5;
  }
  .dropdown {
    button {
      &::after {
        content: none;
      }

      svg {
        font-size: $num20;
        margin: 0 0 0 $num2;
      }
    }
  }
}

.other-option-box {
  border-bottom: 1px solid #f0f0f0;
  &.required-switch {
    margin-bottom: -$num15;
  }

  .otherOption {
    margin-top: $num10;
    display: none;

    .input-group {
      margin: 0 !important;
    }

    .input-group-text {
      font-size: $num12;
      border-radius: 0;
      width: $num70;
      justify-content: center;
      z-index: 1;
      background-color: #f9f9f9;
      color: $grey;
    }

    .form-control {
      padding: $num10;
      font-size: $num12;
      color: $grey;
      &::placeholder {
        font-size: $num12;
      }
    }
  }

  .other-label {
    margin: $num15 0 0;
    line-height: 1;

    label {
      font-weight: $semibold;
      font-size: $num12;
      margin: 0 0 $num5;
      letter-spacing: $num1;
    }

    .form-control {
      width: $per40;
      height: $num40;
      font-size: $num12;
      padding: $num5;
    }
  }
}

.required-switch {
  margin: $num15 0 0;
  background-color: #f9f9f9;
  padding: $num10;

  .display-required {
    display: none;
    background-color: transparent;
    flex: 1;
    padding: 0;
    border: none;
  }
}

/* switch */

.form-switch {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  .form-check-input {
    margin-left: 0;
    margin-top: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.1);
      border-color: $secondaryColor;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
    }

    &:checked {
      background-color: $secondaryColor;
      border-color: $secondaryColor;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");

      ~ .display-required {
        display: block;
        margin-left: $num10;
        padding-left: $num10;
        border-left: 1px solid #ddd;
        font-size: $num12;
        font-weight: $regular;
        &:focus {
          background-color: $white;
          border-left-color: $secondaryColor;
        }
        &::placeholder {
          font-size: $num12;
        }
      }

      ~ .otherOption {
        display: block;
      }
    }
  }
  label {
    font-size: $num12;
    margin-left: $num5;
    margin-right: $num10;
  }
}

/* profile page */
.profile-main {
  .slideLeft {
    .left-tabs {
      button {
        font-size: 0;
        display: grid;
        place-content: center;

        svg {
          margin-right: 0;
          border-right: 0;
          padding: 0 $num5;
        }
      }
    }
  }
  /*remove from the profile main if the same design comes again*/
  .left-tabs {
    .nav-tabs {
      border-bottom: none;
    }
    button {
      width: $per100;
      padding: $num8 $num10;
      color: $primaryColor;
      border-bottom: 1px solid #eaeaea;
      text-align: left;
      border-radius: 0;
      position: relative;
      font-size: $num12;
      transition: all 0.3s;
      letter-spacing: $num1;
      font-weight: $medium;
      &:hover {
        background-color: $white;
        color: $secondaryColor;
        box-shadow: 0 0 20px 0 #0000001c;
      }
      &.active {
        background-color: $secondaryColor;
        color: $white;
        border-color: #eaeaea;
      }

      svg {
        position: relative;
        margin-right: $num10;
        padding-right: $num10;
        font-size: $num30;
        border-right: 1px solid #eaeaea;
      }
    }
  }
  /*remove from the profile main if the same design comes again*/
  .left-tab-content {
    padding: 0 $num30;
    max-width: $per90;
    margin: 0 auto;

    .tab-content {
      .personal-details-form,
      .primary-contact-info,
      .organization-list {
        background-color: $white;
        padding: $num20;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      }
      .row {
        --bs-gutter-x: 20px;
      }
    }
  }

  .right-full {
    .left-tab-content {
      .tab-content {
        padding: $num30;
        .row {
          --bs-gutter-x: 30px;
        }
      }
    }
  }
}

.non-edit-data {
  margin: 0 -10px;
  p {
    @include flex(flex, center, flex-start);
    font-size: $num12;
    padding: $num10;
    font-weight: $medium;
    &:first-child {
      padding-top: 0;
    }

    span {
      font-weight: $semibold;
      min-width: $num100;
      font-size: $num12;
    }
  }

  .organization-subtitles {
    font-size: $num12;
    font-weight: $bold;
    color: $primaryColor;
    text-transform: uppercase;
    letter-spacing: $num1;
  }

  .change-password {
    .form-label {
      @include flex(flex, center, space-between);
    }
  }
}

.edit-icon {
  color: $secondaryColor;
  font-size: $num12;
  cursor: pointer;
  transition: all 0.3s;
  margin-left: $num10;

  svg {
    font-size: $num16;
    vertical-align: top;
  }

  &:hover {
    color: $primaryColor;
  }

  a {
    color: $secondaryColor;
  }
}

/* sort - filter - search design */

.heading-actions {
  @include flex(flex, center, space-between);
  margin-bottom: $num20;

  .heading {
    font-size: $num18;
    line-height: 1;
    font-weight: $medium;
    position: relative;
  }
  .action {
    @include flex(flex, center, flex-end);

    /* filter */
    .filter {
      @include flex(flex, center, flex-end);
      .filter-label {
        color: $grey;
        font-size: $num12;
        font-weight: $semibold;
      }
      .k-multiselect {
        width: auto;
        transition: all 0.3s;
        background-color: $white;
        border: 1px solid #ccc;
        position: relative;
        height: $num30;
        font-weight: $medium;
        border-radius: 0;
        @include action-box-bg();
        &:hover {
          color: $secondaryColor;
          border-color: $secondaryColor;
        }

        svg {
          font-size: $num18;
          margin-left: $num10;
        }

        .k-multiselect-wrap {
          overflow-y: hidden;
          background-color: transparent;
          border-radius: 0;
          border: none;

          ul {
            margin-right: $num20;
            li {
              border: none;
              border-radius: 0;
              padding: $num3 $num6;
              margin: $num2 0 $num3 $num2;
              font-size: $num12;
              background: $secondaryColor;
              color: $white;
              min-height: auto;

              .k-icon {
                font-size: $num14;
              }
            }
          }

          .k-clear-value {
            height: $per100;
            visibility: visible;
          }

          .k-searchbar {
            height: $per100;
            .k-input {
              height: auto;
            }
          }
        }
      }
    }

    .sort {
      .dropdown {
        height: $num30;
      }
    }

    div {
      + div {
        margin-left: $num10;
      }
    }

    .search-bar {
      position: relative;
      @include flex(flex, stretch, flex-end);

      input {
        width: 0;
        transition: all 0.3s;
        padding: 0;
        font-size: $num12;
        border: none;
        @include action-box-bg();
        &.input-expand {
          padding: 0 $num10;
          border: 1px solid #ccc;
          width: 250px;
        }
        &:focus {
          border-color: $secondaryColor;
          background: $white !important;

          + .search-icon {
            color: $secondaryColor;
            border-color: $secondaryColor;
            background: $white !important;
            z-index: 1;
          }
        }
      }

      svg {
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        color: $grey;
        width: $num30;
        height: $num30;
        padding: $num5;
        border: 1px solid #ccc;
        @include action-box-bg();
        margin-left: -$num1;
        &.search-icon {
          border-left: none;
        }
      }
    }

    .dropdown {
      color: $grey;
      font-size: $num12;
      font-weight: $semibold;
      @include flex(flex, center, flex-end);
    }

    .action-area {
      transition: all 0.3s;
      padding: $num5;
      background-color: $white;
      border: 1px solid #ccc;
      position: relative;
      margin-left: $num10;
      font-weight: $medium;
      @include action-box-bg();
      &:hover {
        color: $secondaryColor;
        border-color: $secondaryColor;
      }

      svg {
        font-size: $num18;
        margin-left: $num10;
      }
    }
  }
}

/* dropdown design */
.add-question-main,
.dashboard-main,
header {
  .dropdown {
    .btn {
      color: $secondaryColor;
      font-size: $num12;
      @include flex(flex, center, center);
      padding: 0;
      &:hover {
        color: $primaryColor;
      }
      &::after {
        content: none;
      }

      svg {
        font-size: $num30;
        margin: 0 0 -5px;
      }
    }
    .dropdown-menu {
      background-color: $white;
      box-shadow: 0 10px 20px 0 rgba(0,0,0,0.10);
      border: 1px solid #e6e6e6;
      z-index: 99;
      padding: 0;
      border-radius: 0;
      min-width: max-content;

      li {
        font-size: $num12;
        flex-direction: row;
        padding: 0;
        border-bottom: 1px solid #e6e6e6;
        color: $primaryColor;
        transition: all 0.3s;
        font-weight: $semibold;
        width: $per100;
        border-right: 0;
        &:last-child {
          border: none;
        }
        .dropdown-item {
          padding: $num10;
          @include flex(flex, center, flex-start);
          color: $primaryColor;
          font-weight: $medium;
          cursor: pointer;
          letter-spacing: $num1;
          svg {
            margin-right: $num7;
            font-size: $num25;
            margin-top: -$num1;
            border-right: 1px solid #e6e6e6;
            padding-right: $num7;
          }
          &:hover {
            background-color: #f9f9f9;
            color: $secondaryColor;
          }
        }
      }
    }
  }
}

/* sweet alert style */

/* extend */
%swal2-styled {
  padding: $num10 $num45;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: $white;
  border-radius: 0;
  display: inline-block;
  border: 1px solid $white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
/* extend end */
.swal2-popup {
  width: 500px !important;
  max-width: $per100 !important;
  padding: $num30 !important;
  border: 1px solid $lightGrey !important;
  font-size: $num14 !important;
  border-radius: 0 !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

  .swal2-icon {
    margin: 0 auto;
    width: $num60;
    height: $num60;

    .swal2-icon-content {
      font-size: $num40;
    }

    &.swal2-error {
      [class^="swal2-x-mark-line"] {
        top: $num30;
        width: $num32;
        height: $num4;
      }
    }

    &.swal2-success {
      [class^="swal2-success-line"][class$="tip"] {
        top: $num38;
        left: $num8;
        width: $num17;
      }

      [class^="swal2-success-line"][class$="long"] {
        top: $num30;
        right: $num6;
        width: $num40;
      }
    }
  }

  .swal2-title {
    font-size: $num16;
    font-weight: $semibold;
    color: $primaryColor;
    padding: $num15 0 0;
  }

  .swal2-html-container {
    font-size: $num14;
    color: $grey;
    padding: $num10 0 0;
    line-height: 1.5;
  }

  .swal2-actions {
    margin: $num15 0 0;
  }

  .swal2-styled {
    &.swal2-confirm {
      @extend %swal2-styled;
      background-image: linear-gradient(to right, #1fa2ff 0%, #12d8fa 51%, #1fa2ff 100%) !important;
      &:hover {
        background-position: right center !important; /* change the direction of the change here */
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      }
    }
    &.swal2-cancel {
      @extend %swal2-styled;
      background-image: linear-gradient(to right, #d31027 0%, #ea384d 51%, #d31027 100%) !important;
      &:hover {
        background-position: right center !important; /* change the direction of the change here */
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      }
    }
  }
}
.swal2-radio{
      display: flex;
      flex-direction: column;
    label{
      display: flex;
      align-items: baseline;
      margin-bottom: 20px !important;
    }
}
/* sweet alert style end */

/* settings */

.settings-items {
  @include flex(flex, center, space-between);
  padding: $num10 !important;
  height: $num48;

  span {
    width: calc(100% - 50px);
    word-break: break-word;
  }

  .settings-options {
    position: absolute;
    right: 0;
    border-left: 1px solid #eaeaea;
    top: $num5;
    bottom: $num5;
    width: $num50;
    display: grid;
    place-content: center;

    .form-control {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0 $num5;
      font-size: $num12;
      text-align: center;
    }

    .form-check-input {
      width: 3em;
      height: 1.5em;
    }
  }
}

/* loader */
.loading {
  position: fixed;
  inset: 0;
  background-color: #f5f5f5dd;
  @include flex(flex, center, center);
  flex-direction: column;
  z-index: 99999;
}

.organizatio-primary {
  margin: $num10 $num10 0;
  font-weight: $bold;
  background-color: $bodyBGColor;
  padding: $num10;
  color: $grey;
  letter-spacing: $num1;
}

/* -.- modal -.- */
.modal {
  .modal-content {
    border: none;
    border-radius: 0;

    form {
      .form-input-main {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* -.- edit organization -.- */

.edit-organization {
  .left-panel {
    &.slideLeft {
      .edit-organization-left {
        .btn {
          font-size: 0;
          display: grid;
          place-content: center;

          svg {
            margin-right: 0;
            border-right: 0;
            padding: 0 $num5;
          }
        }
      }
    }

    .edit-organization-left {
      .btn {
        @extend .leftButtons;

        &:hover {
          background-color: $white;
          color: $secondaryColor;
          box-shadow: 0 0 20px 0 #0000001c;
        }
        &.active {
          background-color: $secondaryColor;
          color: $white;
          border-color: #eaeaea;
        }

        svg {
          position: relative;
          margin-right: 10px;
          padding-right: 10px;
          font-size: 30px;
          border-right: 1px solid #eaeaea;
        }
      }
    }
  }

  .edit-org-form,
  .organization-primary,
  .card-history-form {
    background-color: $white;
    padding: $num20;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }

  /* -.- organization form -.- */
  .organization-form {
    max-width: $per90;
    margin-inline: auto;

    .edit-org-form {
      display: flex;

      form {
        flex: 1;
      }

      .organization-logo {
        width: $num100;
        height: $num100;
        position: relative;

        label {
          width: $per100;
          height: $per100;
          background-color: #f2f2f2;
          border-radius: $per100;
          font-weight: $semibold;
          color: #cccccc;
          overflow: hidden;
          position: relative;

          img {
               height: 100%;
    object-fit: cover;
    width: 100%;
          }

          .edit-image-icon {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            background: #32c5fff0;
            line-height: 0;
            padding: $num4 0 $num5;
            font-size: $num15;
            color: $white;
          }
        }

        input {
          position: absolute;
          top: 0;
          left: 0;
          height: $per100;
          border-radius: $per100;
          opacity: 0;
        }
      }
    }

    .main-edit-box {
      + .main-edit-box {
        margin-top: $num50;
      }
    }

    .organization-info {
      margin-left: $num20;

      button {
        margin-top: $num15;
      }
    }

    /* -.- card-history-form -.- */
    .card-history-form {
      p {
        color: $lightGrey;
      }
    }
    /* card details */
    .card-detail {
      background-image: linear-gradient(135deg, #32b3ff 1.8%, rgba(22, 255, 245, 0.6) 97.1%);
      display: block;
      border-radius: $num15;
      padding: $num15 $num20 $num20;
      line-height: 1;
      position: relative;
      overflow: hidden;
      &::before {
        content: "card";
        text-transform: uppercase;
        position: absolute;
        bottom: -$num20;
        right: -$num50;
        font-size: $num115;
        font-weight: $bolder;
        letter-spacing: $num5;
        background: linear-gradient(38deg, #70e3ff 0%, #64daf5 50%, rgb(107,246,245) 100%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
      .payment-details {
        color: $white;
        text-align: right;
        font-size: $num22;
        font-weight: $bold;
        text-shadow: 0 $num1 $num1 #00000025;
        @include flex(flex, center, space-between);
      }

      .card-chip {
        margin-top: $num5;
        svg {
          font-size: $num42;
          filter: drop-shadow(0 2px 1px #00000010);

          path {
            fill: $white;
          }
          g {
            path {
              fill: $secondaryColor;
            }
          }
        }
      }

      form {
        background-color: transparent;
        padding: 0;
        box-shadow: none !important;

        .row {
          --bs-gutter-x: 15px;
        }
        .form-group {
          margin-top: $num15;
          position: relative;
          .form-control {
            background: #ffffff40;
            backdrop-filter: blur(4px);
            -webkit-backdrop-filter: blur(4px);
            border-radius: 10px;
            border: 1px solid #ffffff2e;
            color: $white;
            letter-spacing: $num1;
            font-weight: $semibold;
            text-shadow: 0 $num1 $num1 #a0858525;
            font-size: $num12;
            height: auto;
            padding: $num10;
            &::placeholder {
              color: $white;
              letter-spacing: $num1;
              font-weight: $semibold;
              text-shadow: 0 $num1 $num1 #00000025;
              font-size: $num12;
            }
          }
        }

        .btn-grad {
          border-radius: $num10;
          color: $secondaryColor;
          letter-spacing: $num1;
          font-weight: $regular;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border-radius: 10px;
          border: 1px solid rgba(255, 255, 255, 0.18);
        }
      }
    }
  }
}

.title-edit {
  @include flex(flex, center, space-between);
  border-bottom: 1px solid #dee2e6 !important;
  margin-bottom: $num15;
  padding-bottom: $num10;

  .organization-name {
    margin-bottom: 0;
  }
}

/* -.- kendo dropdown list design -.- */
.filter-multiselect-popup {
  width: max-content !important;
  .k-popup {
    background-color: $white;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.10);
    border: 1px solid #e6e6e6;
    z-index: 99;
    padding: 0;
    border-radius: 0;
    min-width: auto;

    .k-list-scroller {
      .k-list {
        .k-item {
          padding: $num10;
          @include flex(flex, center, flex-start);
          color: $primaryColor;
          font-weight: $medium;
          cursor: pointer;
          letter-spacing: $num1;
          font-size: $num12;
          border-bottom: $num1 solid #e6e6e6;
          width: $per100;
          &.k-state-selected {
            color: $white;
            background-color: $secondaryColor;
            box-shadow: none;
            &:hover {
              background-color: $secondaryColor;
              color: $white;
            }
          }
          &.k-state-focused {
            box-shadow: none;
          }
          &:hover {
            background-color: #f9f9f9;
            color: $secondaryColor;
          }
        }
      }
    }
  }
}

/* secondary header */
.secondary-header {
  position: fixed;
  top: 61px;
  left: 0;
  right: 0;
  z-index: 999;
  max-height: $num60;
  background-color: $white;

  .create-survey-flow {
    @include flex(flex, center, space-between);
    flex-wrap: wrap;
    padding: $num10 0;

    .left-menu {
      ul {
        @include flex(flex, center, flex-start);
        flex-wrap: wrap;
        padding: 0;
        margin: 0;

        li {
          list-style: none;

          a {
            font-size: $num11;
            text-transform: uppercase;
            letter-spacing: $num1;
            font-weight: $medium;

            &.active {
              color: $secondaryColor;
              font-weight: $bold;
            }
          }

          + li {
            margin-left: $num25;
          }
        }
      }
    }

    .right-menu {
      .btn-grad {
        padding: $num5 $num30;

        + .btn-grad {
          margin-left: $num10;
        }
      }
    }

    .add-question-right {
      @include flex(flex, center, flex-end);
      flex-wrap: wrap;

      .total-question-count {
        margin-right: $num20;
        font-size: $num11;
        text-transform: uppercase;
        font-weight: $bold;
        letter-spacing: $num1;
        position: relative;
        padding-right: $num20;
        &::after {
          content: "";
          position: absolute;
          top: -$num19;
          bottom: -$num19;
          right: 0;
          width: $num1;
          background-color: #dee2e6;
        }

        span {
          color: $secondaryColor;
        }
      }
    }
  }
}

// .has-second-header {
  .survey-wrapper {
    .left-panel {
      top: $num115;
      max-height: calc(100vh - 115px);
      overflow-y: auto !important;
      overflow: hidden;
      /* width */
      &::-webkit-scrollbar {
        width: $num3;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: $white;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $lightGrey;
        border-radius: $num10;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb {
        &:hover {
          background: $grey;
        }
      }
    }
  }
// }

/* edit survey title in add question */
.edit-title-language {
  @include flex(flex, center, space-between);
  flex-wrap: wrap;
span.total_page{
    border-radius: 100%;
    display: inline-block;
    margin: 0px 5px;
    font-size: 14px;
    font-weight: 600;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
}
  .survey-title {
    font-size: $num14;
    font-weight: $bold;
    // color: $grey;
    text-transform: capitalize;
    letter-spacing: $num1;

    svg {
      font-size: $num16;
      margin-left: $num10;
      vertical-align: middle;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        color: $secondaryColor;
      }
    }
  }

  .survey-language {
    background-color: #f5f5f5;
    @include flex(flex, center, flex-end);
    flex-wrap: wrap;

    span {
      font-size: $num12;
      color: $primaryColor;
      font-weight: $medium;
    }

    .dropdown {
      .btn {
        color: $primaryColor;
        font-weight: $semibold;

        svg {
          font-size: $num17;
          margin: 0 0 0 $num2;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

/* settings */
.settings-main {
  padding: 0 $num30;
  max-width: $per90;
  margin: 0 auto;

  .survey-settings-title {
    font-size: $num15;
    margin: $num35 0 $num15;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: $num1;
    font-weight: $bold;
  }

  .settings-box {
    @include flex(flex, stretch, space-between);
    flex-wrap: wrap;

    .setting-description {
      width: $per100;
      position: relative;
      @include flex(flex, center, space-between);
      + .setting-description {
        margin-top: $num15;
        border-top: 1px solid #ddd;
        padding-top: $num15;
      }

      .form-switch {
        width: $per100;

        .form-check-input {
          width: $num36;
          border-radius: $num36;
          height: $num18;
        }
        .k-switch{
          position: absolute;
          top: 0px;
          right: 0px;
        }
      }

      .setting-info {
        flex: 0 0 auto;
        width: calc(100% - 100px);

        .titile-line {
          font-size: $num12;
          font-weight: $bold;
          letter-spacing: $num1;
          margin: 0 0 $num8;
          line-height: 1;
        }

        p {
          font-size: $num12;
          font-weight: $regular;
          color: $grey;
          line-height: 1.5;
        }
        .settings-input-text{

        .answer_block{
          color: #32c5ff;
    font-weight: 600;
    margin-top: 10px;
    display: inline-block;
        }
        .answer_block.shadow{
          width: auto;
    height: 25px;
    text-align: center;
    border-radius: 3px;
    line-height: 25px;
    box-shadow: rgba(0,0,0,0.05) 0px 6px 24px 0px, rgba(0,0,0,0.08) 0px 0px 0px 1px !important;
    margin-right: 10px;
    padding: 0 10px;
        }
        }
        .k-form-field{
          margin-top: 5px;
          margin-bottom: 5px;
        }
        span.edit_button{
              transition: all 0.3s;
    padding: 5px;
    border: 1px solid #32c5ff;
    color: #fff;
    background-color: #32c5ff;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
        }
      }

      .setting-actions {
        padding: $num15;
        border-left: 1px solid #ddd;
        width: $num100;
        @include flex(flex, center, center);
        &.checkBoxes {
          flex-direction: column;
          align-items: flex-start;
          padding: 0;

          .form-check {
            min-height: auto;
            padding-left: $num20;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            position: relative;
            + .form-check {
              margin-top: $num10;
            }

            .form-check-input {
              visibility: hidden;
              margin: 0;
              &:checked {
                ~ label {
                  &::before {
                    border-color: $secondaryColor;
                  }
                  &::after {
                    background-color: $secondaryColor;
                  }
                }
              }
            }

            .form-check-label {
              font-size: $num12;
              font-weight: $medium;
              line-height: 1;
              margin: 0;
              &::before {
                content: "";
                width: $num16;
                height: $num16;
                margin: 0;
                position: absolute;
                border: 1px solid #ddd;
                left: $num10;
                top: 0;
                border-radius: $num3;
              }
              &::after {
                content: "";
                width: $num12;
                height: $num12;
                margin: 0;
                position: absolute;
                left: $num12;
                top: $num2;
                border-radius: $num2;
              }
            }
          }
        }

        .form-control {
          padding: $num5;
          line-height: 1;
          text-align: center;
          border-radius: 0;
          font-size: $num12;
          font-weight: $medium;
        }

        span {
          cursor: pointer;
          font-size: $num20;
        }
      }
      .setting-actions.survey_settings{
        .settings-input-text{
              font-size: 13px;
    text-align: center;
    padding: 0 !important;
    
      label{
            margin-left: 0px !important;
    margin-right: 0px !important;
    display: flex;
    align-items: center;
    input{
      margin-right: 3px;
    }
      }
    .k-form-field{
      margin-bottom: 10px;position:relative;
      .k-textarea{resize: none;}
      .k-form-hint{
    right: 8px !important;
    bottom: 8px;
      }
    .k-textbox-container{width: 100% !important;
    .k-input{
          padding: 0 5px;
    height: 100%;
    }
    .k-label{
          font-size: 14px;
    left: 5px;
    }
    }
    .k-list-horizontal{
       .k-radio-item{
            margin: 0 -5px 0 0px !important;
            display: flex;
            input{
              margin-right: 3px;
            }
       }
    }
    }
    
    
        }
       
      }       
    }
  }
}

.edit-mode-actions {
  @include flex(flex, center, flex-end);
  flex-wrap: wrap;
  button {
    margin: 0 0 0 $num5;
    font-size: $num12;
    text-transform: uppercase;
    letter-spacing: $num1;
    padding: $num5;
    min-width: $num80 !important;
    font-weight: $semibold;
  }
}

.normal-mode {
  .edit-button {
    transition: all 0.3s;
    padding: $num5;
    border: $num1 solid $secondaryColor;
    color: $white;
    background-color: $secondaryColor;
    position: absolute;
    top: $num6;
    right: $num30;
    font-size: $num12;
    line-height: 1;
    font-weight: $medium;
    pointer-events:all;
    &:hover {
      background-color: $primaryColor;
      border-color: $primaryColor;
    }
  }

  .data-options {
    @include flex(flex, flex-start, flex-start);
    flex-wrap: wrap;
    margin: $num15 0 0;
    flex-direction: column;
    line-height: 1;

    span {
      margin: 0 $num7 $num7 0;
      background-color: $bodyBGColor;
      padding: $num7;
      border: 1px solid #eee;
      font-size: $num12;
      text-transform: uppercase;
      letter-spacing: $num1;
      border-radius: $num6;
      word-break: break-all;
    }
  }

  .rating_questions{
      span{
        margin: 0;
        background-color: transparent;
        border: 0;
        padding: 0;
      }
      .group_rating_que{
        // float: left;
        // width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
          margin: 0px 0px 15px 0px;
          // display: inline-block;
          word-break: break-all;
        }
        span.k-rating{
          margin: 0;
          // float: right;
              flex: none;
          span.k-rating-container{
            span{
              margin: 0;
            }
          }
        }
      }
  }

  .required-message {
    font-size: $num12;
    letter-spacing: $num1;
    line-height: 1;
    margin: $num7 0 0;
  }

  .uploadMediaImg {
    background-color: #eeeeee;
    padding: $num2;
    width: $num150;
    height: $num150;
    margin: $num10 0 0;
    &:hover {
      background-color: #eeeeee;
    }

    img {
      width: $per100;
      height: $per100;
      object-fit: cover;
    }
  }
}
.uploadMedia {
  position: relative;
  margin: $num10 0 0;
  background-color: #f5f5f5;
  padding: $num3 $num3 $num3 $num10;
  display: none;
  @include flex(none, center, space-between);
  flex-wrap: wrap;
  transition: all 0.3s;
  &:hover {
    background-color: $secondaryColor;
    .questionMedia {
      color: $white;
    }
  }

  .questionMedia {
    color: $primaryColor;
    font-size: $num12;
    font-weight: $medium;
    letter-spacing: $num1;
    transition: all 0.3s;
    flex: 0 0 $num105;
    cursor: pointer;

    svg {
      vertical-align: middle;
      font-size: $num15;
    }
  }
  .file-name {
    background-color: $white;
    padding: $num10;
    margin-left: $num10;
    font-size: $num12;
    font-weight: $medium;
    color: $lightGrey;
    line-height: 1;
    flex: 0 0 calc(100% - 115px);
    cursor: default;
  }

  .remove-media {
    position: absolute;
    top: $per50;
    right: $num10;
    transform: translate(0, -50%);

    svg {
      color: $danger;
      cursor: pointer;
    }
  }
}

audio {
  width: $per100;
  height: $num45;
  margin: $num10 0 0;
}

video {
  width: $num300;
  height: auto;
  border: 2px solid #eee;
  margin: $num10 0 0;
}


/* Start Home */
header.common_head{
  .navbar{
    // padding: 5px 0px;
    .navbar-nav{
      align-items: center;
      justify-content: center;
    }
  }
}
.home_wrap{
  .banner_block{

        display: flex;
    position: relative;
    height: calc(var(--screen-height) - 50px);
    background: linear-gradient(45deg,#1c01ae,hsla(0,0%,100%,0) 105.25%),linear-gradient(0deg,rgba(33,150,243,0) 6.12%,#3c05c2 80.65%);
    align-items: center;
    padding-top: 75px;
    padding-bottom: 0px;
    // background-image:
    // linear-gradient(to top, rgba(0,0,0, 0.95), rgba(0,0,0, 0.65)),url(../img/surveybanner.jpg);
    // background-size: cover;
    // background-attachment: fixed;
    // color: white;
    // height: 400px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // text-align: center;
    //     display: flex;
    // align-items: center;
    // justify-content: center;
    // text-align: center;
    // background: #edf2f4;
    // h1,h4{
    //   color: #fff;
    //   letter-spacing: 2px;
    //   text-transform: capitalize;
    //   margin: 0 0 25px 0;
    // }
    h1{
    font-size: 48px;
    font-weight: 600;
    line-height: 64px;
    color: #fff;
    letter-spacing: 2px;
    font-family: "Outfit",sans-serif;
    }
    h4{
         letter-spacing: 1px;
    margin: 20px 0px 0px 0px;
    font-size: 30px;
    font-weight: 600;
    color: #000;
    }
    p{
        letter-spacing: 1px;
    padding: 15px 0px 0px 0px;
    font-size: 16px;
    color: #fff;
    font-family: "Outfit",sans-serif;
    }
    &:after{
          content: "";
    position: absolute;
    left: -50px;
    bottom: -50px;
    background-color: #fff;
    height: 250px;
    width: 250px;
    border-radius: 0px 350px 0px 0px;
    opacity: 0.075;
    }
    &:before{
          content: "";
    position: absolute;
    right: -50px;
    top: -50px;
    background-color: #fff;
    height: 250px;
    width: 250px;
    border-radius: 0px 0px 0px 250px;
    opacity: 0.075;
    }
  }
  .introductory_offer{
    text-align: center;
    h3{
    font-size: 48px;
    background: -webkit-linear-gradient(180deg, #000, #000, #000, #000);
    // background: -webkit-linear-gradient(180deg, #21dbaa, #00b4ef, #0768dd, #5f1ae5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Outfit",sans-serif;
    font-weight: bold;
    animation: animate 1.75s linear infinite;
    }
    @keyframes animate {
            0% {
                opacity: 0;
            }
            25%{
              opacity: 0.25;
            }
  
            50% {
                opacity: 0.5;
            }
  
            75% {
                opacity: 0.75;
            }

             100% {
                opacity: 0;
            }
    }
  }
  .title_block{
    h2{
      visibility: visible;
      text-align: center;
      color: #000;
      font-family: "Outfit",sans-serif;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 1px;
    }
    p{
      text-align: center;
      letter-spacing: 1px;
      padding: 0 50px;
    }
    .bg_gradiant{
      background-image: linear-gradient(90deg, #000, #000, #000, #000);
      // background-image: linear-gradient(90deg, #21dbaa, #00b4ef, #0768dd, #5f1ae5);
      height: 5px;
      width: 60px;
      margin: 0 auto;
      opacity: 1;
      margin-top: 20px;
    }
  }
  .analytics_block{
    background: #ddd;
    // background: linear-gradient(0deg, #1c01ae 0%, rgba(255,255,255,0) 105.25%),linear-gradient(0deg, rgba(33,150,243,0) 6.12%, #3c05c2 80.65%);
    position: relative;
    // h2{
    //     color: #fff;
    //   }
    //   .bg_gradiant{
    //     background-image: linear-gradient(90deg, #ffffff, #ffffff, #ffffff, #ffffff);
    //   }
    &:before{
          content: "";
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 100vw solid transparent;
    border-top: 50px solid #fff;
    position: absolute;
    top: 0;
    }
    &:after{
          content: "";
    width: 0;
    height: 0;
    border-left: 100vw solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 50px solid #fff;
    position: absolute;
    bottom: 0;
    }
    .analytic_inner_block{
      background: #fff;
    padding: 6px 15px 15px 16px;
    border-radius: 30px 5px 50px 5px;
    border: 1px solid #dee2e6;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      
      h3{
    font-size: 14px;
    text-transform: capitalize;
    background-image: linear-gradient(to right, #1fa2ff 0%, #12d8fa 51%, #1fa2ff 100%);
    display: inline;
    color: #fff;
    position: relative;
    top: 2px;
    left: -14px;
    padding: 7px 15px;
    border-radius: 27px 5px 27px 5px;
      }
      p{
margin-top: 20px;
font-size: 13px;
      }
      ul{
        padding: 15px 0px 0px 15px;
          li{
            label{
              font-weight: 600;
              font-size: 13px;
              color: #1da8fe;
            }
            p{
              margin: 5px 0px 15px 0px;
            }            
            &::marker{
              color: #1da8fe;
            }
          }
        }
    }
    .usp_wrap{
      // position: relative;
      // overflow: hidden;
      // height: 35.5rem;
      height: 500px;
      width: 100%;
      display: flex;
      .usp_wrap_inner{
        background-color: rgb(3, 4, 94);
        // position: absolute;
        // top: 0;
        // transition: left 0.3s ease-in-out;
        // width: 25%;
        // height: 100%;

        box-shadow: inset rgba(0,0,0,0.05) 0 1px, inset rgba(0,0,0,0.05) 0 -1px;
        flex-grow: 1;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        flex-basis: 1px;
        color: #fff;

        .usp_wrap_inner_desc{
          // align-items: flex-start;
          // display: flex;
          // flex-direction: column;
          padding: 25px;
          width: 100%;
          height: 100%;
          transition: opacity 0.2s ease-in-out;    
          &:nth-of-type(1){
            opacity: 1;
          }   
          h3{
            font-size: 18px;
    letter-spacing: 1px;
    font-family: "Outfit",sans-serif;
    font-weight: 600;
    margin-bottom: 15px;
          }  
          p{
            font-family: "Outfit",sans-serif;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 300;
          }
          ul{
                padding-left: 15px;
    margin: 15px 0 0 0;
    li{
          font-size: 12px;
    padding-bottom: 10px;
    label{
      display: inline;
    }
    }
          } 
        }
        &:nth-of-type(2){
          background-color:rgb(2, 62, 138);
          // color: #FFFFFF;
          // left: 25%;
          .usp_wrap_inner_desc{
            opacity: 0.75;
          }
        }
        &:nth-of-type(3){
          // left: 37.5%;
          background-color:rgb(0, 119, 182);
          // color: #FFFFFF;
          .usp_wrap_inner_desc{
            opacity: 0.75;
          }
        }
        &:nth-of-type(4){
          // left: 50%;
          background-color: rgb(0, 150, 199);
          // color: #FFFFFF;
          .usp_wrap_inner_desc{
            opacity: 0.75;
          }
        }
        &:nth-of-type(5){
          // left: 62.5%;
          background-color: rgb(0, 180, 216);
          // color: #FFFFFF;
          .usp_wrap_inner_desc{
            opacity: 0.75;
          }
        }
        &:nth-of-type(6){
          // left: 75%;
          background-color: rgb(39, 161, 185);
          // color: #FFFFFF;
          .usp_wrap_inner_desc{
            opacity: 0.75;
          }
        }
        &:nth-of-type(7){
          // right: 0;
          background-color: rgb(58, 183, 207);
          // color: #FFFFFF;
          .usp_wrap_inner_desc{
            opacity: 0.75;
          }
        }
        &:nth-child(1){
          flex-basis: 150px;
        }
      }
      // .usp_wrap_inner+.usp_wrap_inner{
      //   width: 12.5%;
      //   &:hover{
      //     width: 25%;
      //     left: 12.5%;
      //     right: 0;
      //     .usp_wrap_inner_desc{
      //       opacity: 1;
      //     }
      //   }
      // }

      
        &:hover{
          .usp_wrap_inner{
            flex-basis: 1px;              
            .usp_wrap_inner_desc{
              opacity: 0.75;
            }
            &:hover{
            flex-basis: 150px;
            .usp_wrap_inner_desc{
              opacity: 1;
              }
            }
          }
        }

    }
  }
  .benifits_block{
        background: #ddd;
        // background: linear-gradient(0deg, #1c01ae 0%, rgba(255,255,255,0) 105.25%),linear-gradient(0deg, rgba(33,150,243,0) 6.12%, #3c05c2 80.65%);
    // h2{
    //     color: #fff;
    //   }
    //   .bg_gradiant{
    //     background-image: linear-gradient(90deg, #ffffff, #ffffff, #ffffff, #ffffff);
    //   }
        .plateform_block{
      text-align: center;
      background: #fff;
      padding: 20px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border: 1px solid #dcdcdc;
      border-radius: 10px;
          position: relative;
          margin-top: 25px;
      h2{
  font-size: 18px;
    padding: 30px 0px 5px 0px;
    font-family: "Outfit",sans-serif;
    color: #000;
    font-weight: 600;
    letter-spacing: 1px;
      }
      p{
      font-size: 13px;
    letter-spacing: 1px;
    font-family: "Outfit",sans-serif;
    font-weight: 300;
    color: #000;
      }
      svg{
            // background-image: linear-gradient(90deg, #21dbaa, #00b4ef, #0768dd, #5f1ae5);
            background: #3905c0;
  color: #fff;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  padding: 13px;
  outline: 2px solid white;
  outline-offset: -4px;
      position: absolute;
    top: -30px;
    left: 40%;
      }
    }
  }
  .feature_block{
    background: #fff;
    .center_steps{
          width: 75%;
    margin: 0 auto;
    }
    .feature_process_block{
      float: left;
      width: 180px;
      padding-right: 10px;
      padding: 0;
      
      .feature_process_top_block{
        position: relative;
        margin-bottom: 35px;
        span{
          svg{
            color: #000;
            height: 75px;
            width: 75px;
            border-radius: 100%;
            padding: 20px;
            outline: 2px solid white;
            outline-offset: -4px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
          }
        }
        &:before{
          top: 0;
          left: 0;
          background: url(../img/processbg.png) no-repeat 70px 20px;
          background-size: contain;
          content: "";
          position: absolute;
          height: 35px;
          width: 100%;
          // z-index: -1;
        }
      }
      .feature_process_top_block.alt_br{
        &:before{
          transform: rotateX(180deg);
          bottom: 0;
          top: auto;
        }
      }
      .feature_process_top_block.no_br{
        &:before{
          background: none;
        }
      }
      .feature_process_bottom_block{
        h2{
          font-size: 16px;          
          span{
               color: #1da7fe;
    font-size: 32px;
    text-align: center;
    margin-right: 7px;
    position: relative;
    //         &:before{
    //                  content: "";
    // width: 20px;
    // height: 2px;
    // background: #1da7fe;
    // top: -4px;
    // position: absolute;
    // right: -5px;
    //         }
    //         &:after{
    //                  bottom: -3px;
    // content: "";
    // width: 20px;
    // height: 2px;
    // background: #1da7fe;
    // position: absolute;
    // right: -5px;
    //         }
          }
        }
        p{

    font-size: 11px;
    // letter-spacing: 1px;

        }
      }
      &:last-child{
        padding-right: 0px;
      }
    }
    .desc{
          font-size: 13px;
    letter-spacing: 1px;
    font-family: "Outfit",sans-serif;
    font-weight: 300;
    color: #000;
    }
    .font{
          font-size: 18px;
    letter-spacing: 1px;
    font-family: "Outfit",sans-serif;
    font-weight: 600;
    padding-bottom: 10px !important;
    color: #000;
    }
    .font-black{
font-family: "Outfit",sans-serif;
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 1px;
    color: #000;
    }
    .text-sm{
    font-family: "Outfit",sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #000;
    }
    .w-32{
      width: 8rem;
      text-align: center;
    }
    .flex-auto {
    flex: 1 1 auto;
}
.w-16 {
    width: 4rem;
}
.ml-16 {
    margin-left: 4rem;
}
.mr-16 {
    margin-right: 4rem;
}

.mt-16 {
    margin-top: 4rem;
}
.h-16 {
    height: 4rem;
}
.w-full {
    width: 100%;
    text-align: right;
    img{
      width: 50%;
    }
}
.border {
    border: 1px solid #ddd!important;
}
.border-l-4 {
    border-left-width: 4px;
}
.border-t-4 {
    border-top-width: 4px;
}
.border-r-4 {
    border-right-width: 4px;
}
.border-transparent {
    border-color: transparent;
}
.border-b-4 {
    border-bottom-width: 4px;
}
.rounded-bl-full {
    border-bottom-left-radius: 9999px;
}
.rounded-tr-full {
    border-top-right-radius: 9999px;
}
.rounded-br-full {
    border-bottom-right-radius: 9999px;
}
.rounded-tl-full {
    border-top-left-radius: 9999px;
}
.h-full {
    height: 100%;
}
.border-dashed {
    border-style: dashed;
}
.border-gray-300 {
    border-color: #ddd;
}
*, ::after, ::before {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #ddd;
    color: #000;
}
  }
  .about_block{
    background: #fff;
    box-shadow: 0 -6px 8px -1px rgba(0,0,0,0.13);
    .about_desc_block{
      p{
        color: #000;
        font-family: "Outfit",sans-serif;
        font-size: 13px;
        letter-spacing: 1px;
        font-weight: 300;
      }
    }
  }
  }
/* End Home */
