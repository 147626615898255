/*--- Links --- */
@import "colors";
@import "numbers";

/*--- End Links --- */
@media (min-width:1800px){
}
@media(max-width:1200px){
    .survey-wrapper{
        .surveyDescription{
            h5{
                width: 100%;
                margin-bottom: 15px;
            }
            .survey-timings.float-end{
                float: none !important;
            }
        }
    }
}
@media (min-width:992px) and (max-width:1200px){
}
@media (min-width:992px) and (max-width:1400px){
}
@media (min-width:768px) and (max-width:991px){
}
@media(max-width:991px){
}
@media(min-width:768px) {
}
@media (min-width:320px) and (max-width:768px){
}
@media(max-width:767px){
    .thankyou-message{
        padding: 0 !important;
    }
    .question-main{
        .form-check.group_rate{
            flex-direction: column-reverse !important;
            align-items: self-start !important;
            label{
                max-width: 100% !important;
            }
        }
    }
    .k-rating-container{
        .k-rating-item{
            .k-icon{
                font-size: 16px;
            }
        }
    }
    .survey-wrapper.survey_panel{
        .left-panel{
            display: none;
        }
    }
    .survey-wrapper{
        .right-panel{
            width: calc(100% - 0px) !important;
            left: 0 !important;
        }
        .edit-title-language{
            display: none;
        }
        .surveyDescription{
            h5{
                font-size: 14px;
            }
        }
        .survey-timings{
            font-size: 11px;
            span.start_date,span.end_date{
                font-size: 12px;
            }
            span.end_date{
                padding-left: 5px;
            }
        }
    }
    .question-main{
        .form-check{
            .k-rating-container{
                .k-rating-item{
                    padding-right: 0px;
                }
            }
        }
        .question-text{
            word-break: break-word;
        }
    }
    .survey-wrapper.intro_survey_wrap{
        width: 100%;
    }
}
@media (max-width:650px){
    .container-fluid{
        padding: 0px 25px;
    }
    header.intro_header{
        padding: 10px 0px;
        h4{
            font-size: 16px;
        }
    }    
    .introduction-main{
        p.survey-date{font-size: 11px;
            span.start_date, span.end_date{
                font-size: 12px;
            } 
            span.end_date{
                padding-left: 5px;
            }  
        }
        h5{
            font-size: 16px;
        }
        .px-5{
            padding: 0 !important;
        }
    }
    .thankyou-main{
        .p-5{
            padding:0px !important;
        }
        .thankyou-message-card{
             .thankyou-message{
                 h4{
                    font-size: 14px;
                 }
             }
        }
    }
    .survey-wrapper{
         .left-panel.slideLeft{ 
            width: 40px;
            padding: 3px;
            .btn.btn-added{
                width: 90%;
                font-size: 10px;
                padding: 5px;
            }
        }
    }
}
@media(max-width:575px){ 
}