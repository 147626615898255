$num0: 0px;
$num1: 1px;
$num2: 2px;
$num3: 3px;
$num4: 4px;
$num5: 5px;
$num6: 6px;
$num7: 7px;
$num8: 8px;
$num9: 9px;
$num10: 10px;
$num11: 11px;
$num12: 12px;
$num13: 13px;
$num14: 14px;
$num15: 15px;
$num16: 16px;
$num17: 17px;
$num18: 18px;
$num19: 19px;
$num20: 20px;
$num21: 21px;
$num22: 22px;
$num23: 23px;
$num24: 24px;
$num25: 25px;
$num26: 26px;
$num27: 27px;
$num28: 28px;
$num29: 29px;
$num30: 30px;
$num31: 31px;
$num32: 32px;
$num33: 33px;
$num34: 34px;
$num35: 35px;
$num36: 36px;
$num37: 37px;
$num38: 38px;
$num39: 39px;
$num40: 40px;
$num41: 41px;
$num42: 42px;
$num43: 43px;
$num44: 44px;
$num45: 45px;
$num46: 46px;
$num47: 47px;
$num48: 48px;
$num49: 49px;
$num50: 50px;
$num51: 51px;
$num52: 52px;
$num53: 53px;
$num54: 54px;
$num55: 55px;
$num60: 60px;
$num65: 65px;
$num68: 68px;
$num70: 70px;
$num73: 73px;
$num75: 75px;
$num80: 80px;
$num85: 85px;
$num90: 90px;
$num95: 95px;
$num100: 100px;
$num105: 105px;
$num110: 110px;
$num115: 115px;
$num120: 120px;
$num125: 125px;
$num130: 130px;
$num140: 140px;
$num150: 150px;
$num160: 160px;
$num170: 170px;
$num180: 180px;
$num190: 190px;
$num200: 200px;
$num210: 210px;
$num230: 230px;
$num250: 250px;
$num300: 300px;
$num345: 345px;
$num370: 370px;
$num400: 400px;
$num450: 450px;
$num470: 470px;
$num500: 500px;
$num600: 600px;
$num700: 700px;
$per3: 3%;
$per5: 5%;
$per10: 10%;
$per13: 13%;
$per15: 15%;
$per20: 20%;
$per25: 25%;
$per30: 30%;
$per35: 35%;
$per40: 40%;
$per45: 45%;
$per50: 50%;
$per55: 55%;
$per60: 60%;
$per65: 65%;
$per70: 70%;
$per75: 75%;
$per80: 80%;
$per85: 85%;
$per90: 90%;
$per95: 95%;
$per100: 100%;
